<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Робота із документами
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-form ref="form" v-model="formValid">
              <v-row>
                <v-col cols="12">
                  <v-select
                      :items="operations"
                      v-model="operation"
                      hide-details filled
                      color="grey"
                      label="Операція"
                      :class="!operation ? 'req-star' : ''"
                  />
                </v-col>
                <v-col cols="12">
                  <div class="d-flex">
                    <div style="flex: 1 1">
                      <v-select
                          :items="documents"
                          v-model="document"
                          hide-details filled
                          color="grey"
                          label="Документ"
                          class="mr-2"
                          :disabled="!operation"
                          :class="!document ? 'req-star' : ''"
                      />
                    </div>
                    <div style="flex: 0 0 200px">
                      <date-component v-model="document_date" :disabled="!document" label="Дата документу" :class_="!document_date ? 'req-star' : ''"/>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" v-if="operation === 'create'">
                  <v-switch
                      class="mt-1"
                      v-model="pre_delete"
                      hide-details
                      :disabled="!document"
                      label="Вилучати перед створення"
                      color="secondary"/>
                </v-col>
                <v-col class="pt-3" v-if="operation==='remove'">
                  <span class="success--text" style="font-size: 1.1rem">Відбір документів</span>
                  <v-divider class="mt-1"></v-divider>
                </v-col>
                <v-col cols="12" class="pt-0" v-if="operation === 'remove'">
                  <v-switch
                      class="mt-1"
                      v-model="date_enable"
                      hide-details
                      :disabled="!document"
                      label="Відбір по даті документів"
                      color="secondary"/>
                </v-col>
                <v-col cols="6" v-if="operation === 'remove' && date_enable">
                  <date-component v-model="date_start" :disabled="!date_enable" label="Дата початку" :class_="!date_start ? 'req-star' : ''"/>
                </v-col>
                <v-col cols="6" v-if="operation === 'remove' && date_enable">
                  <date-component v-model="date_end" :disabled="!date_enable" label="Дата закінчення" :class_="!date_end ? 'req-star' : ''"/>
                </v-col>
                <v-col cols="12">
                  <v-btn depressed text block color="secondary darken-1" class="button-accept" :disabled="!documentReady()" @click.stop="crud_operation">
                    Виконати
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import legacyFillAPI from '@/utils/axios/legacy_documents'
import {ALERT_SHOW} from "@/store/actions/alert";
import {endOfMonth} from "@/utils/icons";
import {mapGetters} from "vuex";
import {QUESTION_SHOW} from "@/store/actions/question";

const modalDeleteId = 'legacy-documents-crud'

export default {
  name: "LegacyDocumentsWork",
  components: {
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonthLegacy',
      modalAnswer: 'question_answer'
    })
  },
  data() {
    return {
      delete_watcher: null,
      formValid: false,
      pre_delete: false,
      document: null,
      document_date: null,
      documents: [
        { text: 'Акт виконаних робіт/наданих послуг', value: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME'},
        { text: 'Рахунок за надані послуги', value: 'DOCUMENT_BILL_OUTCOME' },
        { text: 'Податкова накладна (вихідна)', value: 'DOCUMENT_TAX_BILL_OUTCOME' },
      ],
      operations: [
        { text: 'Створити', value: 'create' },
        { text: 'Видалити', value: 'remove' },
      ],
      date_enable: false,
      date_start: null,
      date_end: null,
      operation: null,
    }
  },
  methods: {
    documentReady() {
      if (!this.operation) {
        return false
      }
      if (!this.document) {
        return false
      }
      if (this.date_enable) {
        if (!this.date_start || !this.date_end) {
          return false
        }
      }
      return true
    },
    getOperationDescription(payload) {
      if ("create" in payload) {
        return `Створено: ${payload.create} елементів`
      }
      if ("remove" in payload) {
        return `Вилучено: ${payload.remove} елементів`
      }
    },
    crud_operation() {
      if (this.documentReady()) {
        const payload = {
          text: `Підтвердіть операцію ${this.operation === 'create' ? 'створення' : 'вилучення'} документів`,
          accept_button: true,
          id: modalDeleteId
        }
        this.$store.dispatch(QUESTION_SHOW, payload)

      }
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  const payload = {
                    operation: this.operation,
                    document_type: this.document,
                    document_date: this.document_date,
                    pre_delete: this.pre_delete,
                    date_enable: this.date_enable,
                    date_start: this.date_start,
                    date_end: this.date_end
                  }

                  legacyFillAPI.document_crud(payload)
                      .then(response => response.data)
                      .then(data => {
                        this.$store.dispatch(ALERT_SHOW, { text: this.getOperationDescription(data), color: 'success' })
                      })
                      .catch(err => {
                        const error = err.response.data.detail;
                        this.$store.dispatch(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                      });

                }
              }
            }
          }
      )
    }
  },
  created() {
    this.document_date = endOfMonth(this.current_month)
    this.watch_modal_answer()
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  }
}
</script>

<style scoped>

</style>